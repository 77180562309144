import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import '../assets/scss/styles.scss';
import { isViewDesktop} from '../helpers';
import LanguageProvider from '../providers/LanguageProvider';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Home from '../pages/Home/Home';
import Dashboard from '../pages/Dashboard/Dashboard';
import ThankYou from '../pages/ThankYou/ThankYou';

const App = () => {
    const [isDesktop, setIsDesktop] = useState(false);

  // Add cookies ... 
    // useEffect(() => {
    //     //let res = fetch('https://qw68xgmf64.execute-api.us-east-1.amazonaws.com/dev')
    //     let data = fetch('https://v7z2x5apv7.execute-api.us-east-1.amazonaws.com/dev/compare-yourself/all', {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }})
    //         .then(response => response.json().then(data => console.log('cl data',data)))  
            
           
    // },[]);

    useEffect(() => {
        //const isDesk = isDesktop() ? true : false;
        setIsDesktop(isViewDesktop());
    },[]);

    return (
        <React.Fragment>
            <LanguageProvider>
                <Router>
                    <Switch>

                        <Route exact path="/">
                            <Home isDesktop={isDesktop} />
                        </Route>
                        <Route exact path="/thank-you" >
                            <ThankYou isDesktop={isDesktop} />
                        </Route>
                        <Route exact path="/admin" isDesktop={isDesktop}>
                            <Dashboard />
                        </Route>
            
                    </Switch> 
                </Router>
            </LanguageProvider>
        </React.Fragment>

    )
}
export default withCookies(App);    