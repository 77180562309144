import React, { useState, useEffect } from 'react';
import './style.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import DashInfo from './DashInfo/DashInfo';
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
} from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_SKlTvdH6N', // Your user pool id here
    ClientId: '4udpm9g2hkoqh40q5i8dqqnhpr', // Your client id here
};

let userPool = new CognitoUserPool(poolData);

const Dashboard = () => {
    const [formSignUp, setFormSignUp] = useState(true);
    const [formConfirm, setFormConfirm] = useState(true);
    const [showDash, setShowDash] = useState(false);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState({
                                            Name: '',
                                            Value: '',
                                        });
    const [password, setPassword] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const [username, setUsername] = useState(null);
    const [validationCode, setValidationCode] = useState(null);

    

    


    // let dataEmail = {
    //     Name: 'email',
    //     Value: 'email@mydomain.com',
    // };

    const handleInputChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail({
            'Name': 'email',
            'Value': event.target.value
        });
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handleValidationCodeChange = (event) => {
        setValidationCode(event.target.value);
    }

    const submitSignUpForm = (e) => {
        e.preventDefault();
        console.log('submit form');

        let attributeEmail = new CognitoUserAttribute(email);

        setAttributes([attributeEmail]);

        userPool.signUp(name, password, attributes, null, function(
            err,
            result
        ) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            var cognitoUser = result.user;
            console.log('user name is ' + cognitoUser.getUsername());

            // Show confirmation Screen/Form 
            setFormSignUp(false);
            setFormConfirm(true);

        });

    }

    const submitConfirmAccount = (event) => {
        
        event.preventDefault();
        //let userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: username,
            Pool: userPool,
        };

        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(validationCode, true, function(err, result) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ' + result);
        });

    }

    return (
        <div className="blockwrap dashboard">
            <h1>Sign In</h1>
            {formSignUp && <form id="admin-form" onSubmit={submitSignUpForm}>

                <FormControl fullWidth>
                    <TextField className="filled-basic input-wrap" type="text" label="Name" variant="filled" name="first-name" onChange={handleInputChange} placeholder="Name" required />
                </FormControl>

                <FormControl fullWidth>
                    <TextField className="filled-basic input-wrap" type="email" label="Email" variant="filled" name="email" onChange={handleEmailChange} placeholder="Email" required />
                </FormControl>

                <FormControl fullWidth>
                    <TextField 
                        variant="filled" 
                        className="filled-basic input-wrap" 
                        type="password" 
                        label="Password" 
                        name="password"  
                        placeholder="Password" 
                        required 
                        minLength="8"
                        onChange={handlePasswordChange} 
                    />
                </FormControl>

                <Button type="submit" variant="contained" >Sign In</Button>

            </form>}

            {
                formConfirm && 
                    <form id="confirm-form" onSubmit={submitConfirmAccount}>
                        <FormControl fullWidth>
                            <TextField className="filled-basic input-wrap" type="text" label="Username" variant="filled" name="username" onChange={handleUsernameChange} placeholder="Username" required />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField className="filled-basic input-wrap" type="text" label="validation-code" variant="filled" name="validation-code" onChange={handleValidationCodeChange} placeholder="Validation Code" required />
                        </FormControl>

                        <Button type="submit" variant="contained" >Confirm Account</Button>
                    </form>
            }

            {showDash && <DashInfo />}
        </div>
    )
}
export default Dashboard;