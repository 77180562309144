import React, {useState} from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import {TranslatableText} from '../../providers/LanguageProvider';

import Wrapper from '../../templates/Wrapper/Wrapper';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import imgHero from './i/hero-bg.png';
import imgHeroDesk from './i/hero-bg-desktop.png';
//import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
//import MenuItem from '@mui/material/MenuItem';

const AGENCIES = [
    {name: 'Agency'},
    {name: 'Adam & Eve'},
    {name: 'AKQA'},
    {name: 'Alma'},
    {name: 'Anomaly'},
    {name: 'Arnold'},
    {name: 'Arrivals + Departures'},
    {name: 'BBDO'},
    {name: 'BBH'},
    {name: 'Broken Heart Love Affair'},
    {name: 'Butler, Shine, Stern & Partners'},
    {name: 'Camp Jefferson'},
    {name: 'Carmichael Lynch'},
    {name: 'Cheil'},
    {name: 'Cossette'},
    {name: 'Cramer-Krasselt'},
    {name: 'Crispin Porter & Bogusky'},
    {name: 'Cutwater'},
    {name: 'David'},
    {name: 'DDB'},
    {name: 'Dentsu'},
    {name: 'Deutsch'},
    {name: 'Doug + Partners'},
    {name: 'Droga5'},
    {name: 'Fallon'},
    {name: 'Forsman & Bodenfors'},
    {name: 'FUSE Create'},
    {name: 'Giants & Gentlemen'},   
    {name: 'Goodby, Silverstein & Partners'},   
    {name: 'Grey'},   
    {name: 'Gut'},   
    {name: 'Havas'},   
    {name: 'Here Be Monsters'},   
    {name: 'Hill Holiday'},   
    {name: 'Huge'},   
    {name: 'Johannes Leonardo'},   
    {name: 'John St.'},  
    {name: 'Juliet'},  
    {name: 'Juniper Park'},  
    {name: 'Leo Burnett'},  
    {name: 'Lg2'},  
    {name: 'Lowe'},   
    {name: 'McCann'},   
    {name: 'Mother'},   
    {name: 'Ogilvy'},   
    {name: 'OneMethod'},
    {name: 'Open'},   
    {name: 'Publicis'},   
    {name: 'R/GA'},   
    {name: 'Publicis'},   
    {name: 'Saatchi & Saatchi'},   
    {name: 'Sid Lee'},   
    {name: 'Strawberry Frog'},   
    {name: 'Taxi'},   
    {name: 'TBWA'}, 
    {name: 'The Local Collective'}, 
    {name: 'The Martin Agency'}, 
    {name: 'Wunderman Thompson'}, 
    {name: 'Wunder'},    
    {name: 'Zerotrillion'},    
    {name: 'Zulu Alpha Kilo'},    
    {name: '123w'},    
    {name: '180'},  
    {name: '72andSunny'},
    {name: 'Other'}    
]

const Home = (props) => {
    const [name, setName] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [error, setError] = useState(null);

    let history = useHistory();

    const submitForm = (e) => {
        e.preventDefault();
        console.log('submit form');

        if(name === '' || agencyName === ''){
            setError('oops something went wrong...');
        }else{
            const formData = JSON.stringify({ name: name, agency: agencyName});

            // Put https://qw68xgmf64.execute-api.us-east-1.amazonaws.com/dev
            let data =  fetch('https://qw68xgmf64.execute-api.us-east-1.amazonaws.com/dev/sign-up', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        })
                        .then(response => response.json().then(data => {
                            // history thank you page...
                            console.log('success ', data);
                            history.push('/thank-you');
                        })
                        .catch(err => {
                            console.log('eror ', err);
                        })) 

            // OnSuccess history thank you. 
        }

        //const formData = JSON.stringify({ age: 54, height: 56, income: 3099})
        // make post/put request here.
        // let data = fetch('https://v7z2x5apv7.execute-api.us-east-1.amazonaws.com/dev/compare-yourself', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: formData
        //     })
        //     .then(response => response.json().then(data => console.log('cl data',data)))  
    }

    const handleSelectChange = (event) => {
        setAgencyName(event.target.value);
    }

    const handleInputChange = (event) => {
        setName(event.target.value);
    }

    return (
        <Wrapper pageCurrent="home">
                
            <div className="blockwrap hero">
                {!props.isDesktop ? 
                    <img src={imgHero} alt="no meeting mondays" />
                :
                    <img src={imgHeroDesk} alt="no meeting mondays" />
                }
            </div>

            <div className="blockwrap copy">
                <p>Client presentations on a Monday are the worst. It means you’re spending all weekend working on the presentation. It’s totally unnecessary. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. Whether you’re an intern or an ECD, this is something we can all get behind.
                <br />
                <br />
                Sign the petition below to help everyone in the industry know we’re done with client meetings on Mondays. With enough support maybe it’ll even become an industry standard.</p>
            </div>

            <div className="blockwrap petition">
                <ContainerContent>
                    <form id="petition-sign-up" onSubmit={submitForm}>
                        
                        <FormControl fullWidth>
                            <TextField className="filled-basic" type="text" label="Name" variant="filled" name="first-name" onChange={handleInputChange} placeholder="Name" required />
                        </FormControl>
                        
                        {/* <FormControl fullWidth>
                            <TextField className="filled-basic" type="email" label="Email" variant="filled" name="last-name" placeholder="email" required />
                        </FormControl> */}
                        
                        <FormControl fullWidth>
                        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Agency
                        </InputLabel> */}
                        <NativeSelect
                            defaultValue={''}
                            inputProps={{
                                name: 'Agency',
                                id: 'uncontrolled-native',
                            }}
                            onChange={handleSelectChange}
                            required
                        >
                                {AGENCIES.map((agency, index) => {
                                    if(index === 0){
                                        return <option value="" key={index}>{agency.name}</option>
                                    }
                                    return <option value={agency.name} key={index}>{agency.name}</option>
                                })}
                               
                            </NativeSelect>
                        </FormControl>
                        <Button type="submit" variant="contained" >Sign the petition</Button>
                    </form>
                    {/* 
                        <div className="petition-info"> 
                            <div className="heading-wrap">
                                <h2><span>2578</span> have signed the petition</h2>
                            </div>
                            <div>{error}</div>
                        </div> 
                    */}
                </ContainerContent>
                
            </div>
        
        </Wrapper>

    )
}
export default Home;