import React from 'react';
import './style.scss';
import imgTwitter from './i/twitter.svg';
import imgLinkedin from './i/linkedin.svg';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton,  TwitterIcon } from 'react-share';

const SocialShare = (props) => {
    let tweetCopy1 = `Proud%20to%20say%20we%20don%E2%80%99t%20have%20client%20meetings%20on%20Mondays.%20So%20our%20teams%20don%E2%80%99t%20have%20to%20spend%20the%20weekend%20building%20presentations.%20You%20can%20easily%20book%20a%20meeting%20on%20any%20other%20day%20of%20the%20week.%20Let%E2%80%99s%20leave%20weekends%20alone.%20%23NoMeetingMondays%20&url=https://www.nomeetingmondays.com%20pic.twitter.com/Bp6D6coG8N`;
    let tweetCopy2 = `I%20support%20the%20campaign%20for%20no%20client%20meetings%20on%20Mondays.%20No%20one%20wants%20to%20work%20the%20weekend%20if%20they%20don%E2%80%99t%20have%20to.%20You%20can%20easily%20book%20a%20meeting%20on%20any%20other%20day%20of%20the%20week.%20Let%E2%80%99s%20leave%20weekends%20alone.%20%23NoMeetingMondays%20&url=https://www.nomeetingmondays.com%20pic.twitter.com/Bp6D6coG8N`;
   
    return (
       
        props.option ? 
            <div className="social-share">
                <a  className="btn-share twitter"  href={`https://twitter.com/intent/tweet?text=${tweetCopy1}`} target="_blank" rel="noopener noreferrer">
                    <img src={imgTwitter} className="icon-twitter" alt="twitter bird" />
                </a>
                {/* <TwitterShareButton 
                    resetButtonStyle={false}
                    className="btn-share twitter" 
                    url="https://www.nomeetingmondays.com" 
                    title="Proud to say we don’t have client meetings on Mondays. So our teams don’t have to spend the weekend building presentations. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. #NoMeetingMonday" >
                    <img src={imgTwitter} className="icon-twitter"/>
                </TwitterShareButton> */}

                <LinkedinShareButton 
                     resetButtonStyle={false}
                     className="btn-share linkedin" 
                     url="https://www.nomeetingmondays.com" 
                     title="No meeting Mondays" 
                     summary="Proud to say we don’t have client meetings on Mondays. So our teams don’t have to spend the weekend building presentations. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. #NoMeetingMonday" source="https://www.nomeetingmondays.com">
                    <img src={imgLinkedin} className="icon-linkedin" alt="linkedin" />
                </LinkedinShareButton>
            </div>
        :
            <div className="social-share">
                <a  className="btn-share twitter"  href={`https://twitter.com/intent/tweet?text=${tweetCopy2}`} target="_blank" rel="noopener noreferrer">
                    <img src={imgTwitter} className="icon-twitter" alt="twitter bird" />
                </a>
                {/* <TwitterShareButton 
                    resetButtonStyle={false}
                    className="btn-share twitter" 
                    url="https://www.nomeetingmondays.com" 
                    title="I support the campaign for no client meetings on Mondays. No one wants to work the weekend if they don’t have to. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. #NoMeetingMonday" >
                    <img src={imgTwitter} className="icon-twitter"/>
                </TwitterShareButton> */}

                <LinkedinShareButton 
                    resetButtonStyle={false}
                    className="btn-share linkedin" 
                    url="https://www.nomeetingmondays.com" 
                    title="No meeting Mondays" 
                    summary="I support the campaign for no client meetings on Mondays. No one wants to work the weekend if they don’t have to. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. #NoMeetingMonday" source="https://www.nomeetingmondays.com">
                    <img src={imgLinkedin} className="icon-linkedin" alt="linkedin"/>
                </LinkedinShareButton>
            </div>
            
            
        

       

    )
}
export default SocialShare;


