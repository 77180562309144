import React from 'react';

const DashInfo = (props) => {

    return (
        <div>
              <h1>Dash Info</h1>
              <p>Show the entries</p>
        </div>

    )
}
export default DashInfo;