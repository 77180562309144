import React from 'react';
import './style.scss';
//import { Link } from 'react-router-dom';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';

class Footer extends React.Component {
  render() {
    return (
        <footer>
            <ContainerContent>
                <p>&copy; Rethink Communications</p>
                {/* <Link to="/admin">Admin</Link> */}
            </ContainerContent>
        </footer>
    )
  }
}
export default Footer;