import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import imgHero from '../Home/i/hero-bg.png';
import imgHeroDesk from '../Home/i/hero-bg-desktop.png';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import SocialShare from '../../components/SocialShare/SocialShare';


const ThankYou = (props) => {

    return (

        <Wrapper pageCurrent="thank-you">
            <div className="blockwrap hero">
                {!props.isDesktop ? 
                    <img src={imgHero} alt="no meeting mondays" />
                :
                    <img src={imgHeroDesk} alt="no meeting mondays" />
                }
            </div>

            <div className="blockwrap complete-support">
                <ContainerContent>
                    <h2>Complete your support</h2>
                    <div className="steps">
                        <div className="circle">
                            1
                        </div>
                        <div className="line"></div>
                        <div className="circle">
                            2
                        </div>
                        <div className="line"></div>
                        <div className="circle last">
                            3
                        </div>
                    </div>
                    <h3>Thanks for the support. <br /> Share with your network so we get even more people onboard. </h3>
                </ContainerContent>
               
            </div>

            <div className="blockwrap share-options">

                <ContainerContent>
                    <div className="share-option">
                        <h4>We don’t do meetings <br />on Mondays.</h4>
                        <p>Proud to say we don’t have client meetings on Mondays. So our teams don’t have to spend the weekend building presentations. You can easily book a meeting on any other day of the week.<br /> Let’s leave weekends alone.<br /> #NoMeetingMondays</p>
                        <SocialShare option={true} />
                        {/* <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.nomeetingmondays.com`} target="_blank" rel="noopener noreferrer">Share Linkedin</a> */}
                    </div>

                    <div className="share-option">
                        <h4>Say no to meetings <br />on Mondays.</h4>
                        <p>I support the campaign for no client meetings on Mondays. No one wants to work the weekend if they don’t have to. You can easily book a meeting on any other day of the week. Let’s leave weekends alone. #NoMeetingMondays<br /><br /></p>
                        <SocialShare option={false} />
                    </div>
                </ContainerContent>
                
            </div>
        </Wrapper>

    )
}
export default ThankYou;