import React from 'react';
import './style.scss';
import Footer from '../../components/Footer/Footer';

const Wrapper = ( {children, pageCurrent}) => {

return (
        <div className={`wrapper ${pageCurrent}`}>
            {children}
            <Footer />
        </div>
    )
}
export default Wrapper;